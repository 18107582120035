import request from "@/utils/request";

// 预约儿童科学乐园门票
export const playgroundApt = (data) => {
  return request({
    url: "/we_chat/playground/apt",
    method: "post",
    data,
  });
};
// 儿童科学乐园门票排期列表
export const playgroundList = (params) => {
  return request({
    url: "/we_chat/playground/schedule/list",
    method: "get",
    params,
  });
};
// 儿童科学乐园门票检查某日期门票订单
export const hasTicketOrder = (params) => {
  return request({
    url: "/we_chat/playground/apt/hasTicketOrder",
    method: "post",
    params,
  });
};
//获取门票排期列表
export const ticketScheduleList = () => {
  return request({
    url: "/we_chat/ticket/schedule/list",
    method: "get",
  });
};

//查询配置
export const getCommonConfig = (params) => {
  return request({
    url: "/we_chat/base/ticket/config",
    method: "get",
    params,
  });
};

// 预约门票-个人
export const ticketPersonal = (data) => {
  return request({
    url: "/we_chat/ticket/apt/personal",
    method: "post",
    data,
  });
};

// 预约门票-团队
export const ticketTeam = (data) => {
  return request({
    url: "/we_chat/ticket/apt/team",
    method: "post",
    data,
  });
};
