import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "amfe-flexible";
import Vant from "vant/es";
import { Toast } from "vant";
import "vant/lib/index.css";
import "./assets/css/resetui.css";
import "./assets/css/quill.core.css";
// import Vconsole from "vconsole";
// const vConsole = new Vconsole();

store._state.data.href = location.href.split("#")[0];
Toast.setDefaultOptions({ duration: 3000 });
createApp(App).use(store).use(router).use(Vant).mount("#app");
