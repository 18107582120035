<template>
  <div class="myNotice">
    <van-popup v-model:show="show" :close-on-click-overlay="!isShow">
      <div class="content">
        <h4>{{ text }}须知</h4>
        <div style="height: 100%" class="pHtml" v-html="pHtml"></div>
        <van-button
          v-if="isShow"
          type="primary"
          class="btn_agree"
          :disabled="disabled"
          @click="agree"
          >我已阅读，同意{{ text
          }}<span v-show="time > 0"> ({{ time }})</span></van-button
        >
        <div class="btn_not" v-if="isShow">
          <span @click="noAppointment">暂不{{ text }}</span>
        </div>
        <van-icon name="close" @click="noAppointment" v-else class="close" />
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      disabled: false,
      time: 0,
      timer: null,
      pHtml: "",
      isShow: true,
      type: "",
      text: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    init(type, isShow = true, notice) {
      this.type = type;
      switch (this.type) {
        case "科技馆门票预约":
        case "儿童科学乐园门票预约":
          this.text = "预约";
          break;
        case "临展":
        case "活动":
          this.text = "报名";
          break;
        case "影票购买":
          this.text = "购买";
          break;
        default:
          break;
      }
      this.isShow = isShow;
      this.pHtml = notice;
      this.disabled = true;
      this.time = 3;
      if (this.isShow) {
        this.timer = setInterval(() => {
          this.time--;
          if (this.time <= 0) {
            this.disabled = false;
            clearInterval(this.timer);
          }
        }, 1000);
      }
      if (this.isShow) {
        if (this.type === "活动") {
          this.show = true;
        } else {
          if (sessionStorage.getItem(this.type)) {
            this.agree();
          } else {
            this.show = true;
          }
        }
      } else {
        this.show = true;
      }
    },
    agree() {
      sessionStorage.setItem(this.type, 1);
      this.$emit("agree");
    },
    noAppointment() {
      clearInterval(this.timer);
      this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.myNotice {
  ::v-deep .van-popup {
    width: 90vw;
    height: 85vh;
    background: transparent;
    .content {
      position: relative;
      background: #fff;
      height: 76vh;
      border-radius: 10px;
      padding: 0 20px 20px;
      color: #000;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h4 {
        font-size: 18px;
        line-height: 50px;
        text-align: center;
      }
      .pHtml {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.8;
        text-align: left;
        overflow-y: auto;
      }
      .btn_agree {
        width: 75%;
        margin: 15px auto 0;
        border-radius: 4px;
        line-height: 44px;
        min-height: 44px;
        .van-button__text,
        .van-button__text span {
          font-weight: 650;
          font-style: normal;
          font-size: 16px;
        }
      }
      .btn_not {
        position: absolute;
        bottom: -5vh;
        font-weight: 650;
        font-size: 16px;
        color: #fff;
        left: 0;
        right: 0;
        text-align: center;
      }
      .close {
        position: absolute;
        bottom: -5vh;
        font-size: 26px;
        color: #fff;
        left: 0;
        right: 0;
        text-align: center;
      }
    }
  }
}
</style>
