import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";
import Home from "@/views/Home copy 2.vue";
// import Home from "@/views/Home.vue";//维护中

import store from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "温州科技馆" },
  },
  {
    path: "/ticketReservation",
    name: "TicketReservation",
    component: () => import("@/views/TicketReservation copy.vue"),
    meta: {
      // title: "门票预约",
      title: "个人预约",
      hidden: true,
    },
  },
  {
    path: "/reservation",
    name: "Reservation",
    component: () => import("@/views/Reservation"),
    meta: {
      title: "科技馆门票预约",
      rightBtn: "预约须知",
      hidden: true,
    },
  },
  {
    path: "/teamReservation",
    name: "TeamReservation",
    component: () => import("@/views/TeamReservation"),
    meta: {
      title: "团队预约",
      rightBtn: "预约须知",
      hidden: true,
    },
  },
  {
    path: "/shadowTicket",
    name: "ShadowTicket",
    component: () => import("@/views/ShadowTicket"),
    meta: {
      title: "影票购买",
      rightBtn: "购买须知",
      hidden: true,
    },
  },
  {
    path: "/movieTicketReservation",
    name: "MovieTicketReservation",
    component: () => import("@/views/MovieTicketReservation"),
    meta: {
      title: "影票购买",
      rightBtn: "购买须知",
      hidden: true,
    },
  },
  {
    path: "/myTicket",
    name: "MyTicket",
    component: () => import("@/views/MyTicket"),
    meta: {
      title: "我的门票",
      hidden: true,
    },
  },
  {
    path: "/myTicketActivity",
    name: "MyTicketActivity",
    component: () => import("@/views/MyTicketActivity"),
    meta: {
      title: "活动报名记录",
      hidden: true,
    },
  },
  {
    path: "/ticketDetails",
    name: "TicketDetails",
    component: () => import("@/views/TicketDetails"),
    meta: {
      title: "门票详情",
      hidden: true,
    },
  },
  {
    path: "/myActivityDetails",
    name: "MyActivityDetails",
    component: () => import("@/views/MyActivityDetails"),
    meta: {
      title: "活动详情",
      hidden: true,
    },
  },
  {
    path: "/pacificColiseum",
    name: "PacificColiseum",
    component: () => import("@/views/PacificColiseum"),
    meta: {
      title: "场馆简介",
      hidden: true,
    },
  },
  {
    path: "/activityList",
    name: "ActivityList",
    component: () => import("@/views/ActivityList"),
    meta: {
      title: "临展报名",
      hidden: false,
    },
  },
  {
    path: "/activityDetails",
    name: "ActivityDetails",
    component: () => import("@/views/ActivityDetails"),
    meta: {
      title: "临展详情",
      hidden: true,
    },
  },
  {
    path: "/activityReservation",
    name: "ActivityReservation",
    component: () => import("@/views/ActivityReservation"),
    meta: {
      title: "临展报名",
      rightBtn: "报名须知",
      hidden: true,
    },
  },
  {
    path: "/commonlyUsed",
    name: "CommonlyUsed",
    component: () => import("@/views/CommonlyUsed"),
    meta: {
      title: "常用观众",
      hidden: true,
    },
  },
  {
    path: "/myFeedback",
    name: "MyFeedback",
    component: () => import("@/views/Feedback/MyFeedback"),
    meta: {
      title: "意见反馈",
      hidden: true,
    },
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: () => import("@/views/Feedback/Feedback"),
    meta: {
      title: "意见反馈",
      hidden: true,
    },
  },
  {
    path: "/feedbackDetails",
    name: "FeedbackDetails",
    component: () => import("@/views/Feedback/FeedbackDetails"),
    meta: {
      title: "意见反馈",
      hidden: true,
    },
  },
  {
    path: "/smallTicket",
    name: "SmallTicket",
    component: () => import("@/views/SmallTicket"),
    meta: {
      title: "支付完成",
    },
  },
];

const env = process.env.NODE_ENV;

const router = createRouter({
  history: env === "production" ? createWebHistory() : createWebHashHistory(),
  routes,
});

router.beforeEach((to, form, next) => {
  if (form.path === "/myTicket") {
    if (to.path === "/ticketDetails") {
      store.state.include = ["MyTicket"];
    } else {
      store.state.include = [];
    }
  } else if (form.path === "/myTicketActivity") {
    if (to.path === "/myActivityDetails") {
      store.state.include = ["MyTicketActivity"];
    } else {
      store.state.include = [];
    }
  }
  next();
});

export default router;
