<template>
  <div class="app">
    <van-sticky v-show="hidden">
      <van-nav-bar
        :title="title"
        :right-text="rightBtn"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
      />
    </van-sticky>
    <router-view
      v-slot="{ Component }"
      :clickLeftNum="clickLeftNum"
      :clickRightNum="clickRightNum"
      v-model:rightBtn="rightBtn"
    >
      <keep-alive :include="['Home'].concat($store.state.include)">
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <van-overlay :show="$store.state.loading" class="curtain">
      <van-loading type="spinner" color="#ffffff" vertical
        >加载中...</van-loading
      >
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      title: "",
      hidden: false,
      rightBtn: "",
      clickLeftNum: 0,
      clickRightNum: 0,
    };
  },
  components: {},
  created() {},
  mounted() {},
  methods: {
    onClickLeft() {
      let path = this.$route.path;
      if (["/reservation", "/movieTicketReservation"].includes(path)) {
        this.clickLeftNum++;
      } else if (["/myTicket"].includes(path)) {
        this.$router.push("/");
      } else {
        this.$router.go(-1);
      }
    },
    onClickRight() {
      this.clickRightNum++;
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data.meta) {
          this.hidden = data.meta.hidden;
          this.title = data.meta.title;
          this.rightBtn = data.meta.rightBtn;
        }
        if (data.query && data.query.type) {
          this.title = data.query.type;
        }
      },
    },
  },
};
</script>

<style lang="scss">
.app {
  .curtain {
    background-color: #ffffff00;
    display: flex;
    justify-content: center;
    align-items: center;
    .van-loading {
      background-color: #333;
      padding: 20px;
      border-radius: 10px;
    }
  }
}
* {
  margin: 0;
}
</style>
