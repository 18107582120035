<template>
  <div
    class="wx-open-subscribe-container"
    style="position: absolute; width: 100%; height: 100%; top: 0px"
  >
    <wx-open-subscribe
      :template="templateId"
      id="subscribe-btn"
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        overflow: hidden;
      "
      @success="openSubscribeSuccess"
      @error="openSubscribeError"
    >
      <component :is="'script'" type="text/wxtag-template">
        <div style="width: 100%; height: 500px"></div>
      </component>
    </wx-open-subscribe>
  </div>
</template>
<script>
import { identify } from "@/api/api";
export default {
  props: {
    templateId: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  created() {
    this.getIdentify();
  },
  methods: {
    async getIdentify() {
      let {
        data: { appId, nonceStr, signature, timestamp },
      } = await identify({
        url: this.$store._state.data.href,
      });
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId, // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList: [], // 必填，需要使用的JS接口列表
        openTagList: ["wx-open-subscribe"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      });
    },
    openSubscribeSuccess(e) {
      this.$emit("open-subscribe-success", e.detail);
    },
    openSubscribeError(e) {
      this.$emit("open-subscribe-error", e.detail);
    },
  },
};
</script>
