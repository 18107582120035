import request from "@/utils/request";

// // 登录方法
// export function login(username, password, code, uuid) {
//   const data = {
//     username,
//     password,
//     code,
//     uuid,
//   };
//   return request({
//     url: "/login",
//     headers: {
//       isToken: false,
//     },
//     method: "post",
//     data: data,
//   });
// }

// 获取授权登录Url
export const get_login_url = (params) => {
  return request({
    url: "/we_chat/get_login_url",
    method: "get",
    params,
    headers: {
      isToken: false,
    },
  });
};

// 获取用户信息
export const get_user_info = () => {
  return request({
    url: "/we_chat/get_user_info",
    method: "get",
  });
};

// 获取验证码
export const get_verify_code = (params) => {
  return request({
    url: "/we_chat/get_verify_code",
    method: "get",
    params,
  });
};

// 绑定手机号
export const bind_phone = (data) => {
  return request({
    url: "/we_chat/bind_phone",
    method: "post",
    data,
  });
};
