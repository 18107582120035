<template>
  <van-dialog
    v-model:show="show"
    class="my_tips"
    :showConfirmButton="false"
    :closeOnClickOverlay="true"
  >
    <template v-slot:title>
      <van-icon name="cross" class="cross" @click="show = false" />
      <div class="title"><van-icon name="warning" /><span>提示！</span></div>
    </template>
    <template v-slot:default>
      <div class="content" v-html="content"></div>
      <div class="gotIt_btn">
        <van-button type="primary" @click="confirm">{{
          buttonText
        }}</van-button>
      </div>
    </template>
  </van-dialog>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  props: {
    toPath: {
      type: String,
      default: () => '',
    },
    content: {
      type: String,
      default: () => '',
    },
    buttonText: {
      type: String,
      default: () => '知道了',
    },
  },
  created() {},
  mounted() {},
  methods: {
    init() {
      this.show = true;
    },
    confirm() {
      if (this.toPath) {
        this.$router.push(this.toPath);
      }
      this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.my_tips {
  .van-dialog__header {
    .cross {
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 20px;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      .van-icon {
        color: #e18701;
        font-size: 24px;
      }
      span {
        font-size: 15px;
        color: #333;
        margin-left: 10px;
        font-weight: 650;
        font-family: 'PingFangSC-Semibold';
      }
    }
  }
  .van-dialog__content {
    .content {
      margin: 10px 0 15px;
      padding: 0 20px;
      text-align: center;
    }
    .gotIt_btn {
      display: flex;
      justify-content: center;
      margin: 10px 0;
      .van-button {
        width: 80px;
        height: 30px;
        border-radius: 15px;
      }
    }
  }
}
</style>
<style lang="scss">
::v-deep .tips {
  .van-dialog__footer {
    padding: 20px 100px;
  }
}
</style>
