import axios from "axios";
import { Toast } from "vant";
import { getToken } from "@/utils/auth";
import errorCode from "@/utils/errorCode";
import store from "@/store";
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    // const token =
    //   "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImNmMWE1NDdmLTI0M2MtNGQ4OC04NDJmLTU4MjE2YjI1MjM0MSJ9.0uHBs2-oy5u6Rk5kPArSBkEFnGPEHe1PlFINNph5_JkxO7qr82fjjp80SJoSBlIzHbhW5sgvfiQ3ER3kQQ5eoA";
    const isToken = (config.headers || {}).isToken === false;
    if (getToken() && !isToken) {
      config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // config.headers["Authorization"] = "Bearer " + token;
    store.state.loading = true;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode["default"];
    // 二进制数据则直接返回
    if (
      res.request.responseType === "blob" ||
      res.request.responseType === "arraybuffer"
    ) {
      return res.data;
    }
    store.state.loading = false;
    if (code === 401) {
      // Toast.fail(msg);
      store.dispatch("Login");
      return Promise.reject("无效的会话，或者会话已过期，请重新登录。");
    } else if (code === 500) {
      Toast.fail(msg);
      return Promise.reject(new Error(msg));
    } else if (code !== 200) {
      Toast.fail(msg);
      return Promise.reject("error");
    } else {
      return res.data;
    }
  },
  (error) => {
    let { message } = error;
    if (message === "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统繁忙，请稍后重试";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    store.state.loading = false;
    Toast.fail(message);
    return Promise.reject(error);
  }
);
export default service;
