import { createStore } from "vuex";
import { get_login_url, get_user_info } from "@/api/login";
import { getToken } from "@/utils/auth";
// import router from "@/router";
export default createStore({
  state: {
    token: getToken(),
    include: [],
    info: {},
    loading: false,
    getUrl: true,
    href: null,
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    SET_NUM: (state, num) => {
      state.num = num;
    }
  },
  actions: {
    Login({ commit, state }) {
      if (state.getUrl) {
        state.getUrl = false;
        return new Promise((resolve, reject) => {
          // 获取当前页面的url
          let params = {
            url: window.location.href,
          }
          get_login_url(params)
            .then((res) => {
              window.location.href = res.data;
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
      // }
    },
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        get_user_info()
          .then((res) => {
            commit("SET_INFO", res.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }
});
