import request from "@/utils/request";

// 上传文件
export const upload = (data) => {
  return request({
    url: "/common/upload",
    method: "post",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 场馆信息
export const basic_config_select = () => {
  return request({
    url: "/system/basic_config/select",
    method: "get",
  });
};

// 首页轮播图
export const carouselManagementList = (params) => {
  return request({
    url: "/we_chat/index/carousel/list",
    method: "get",
    params,
  });
};

// 一键关闭功能（预约）
export const basic_config_get_all_close = () => {
  return request({
    url: "/we_chat/base/get_all_close",
    method: "get",
  });
};

// 上传
export const ossUpload = (data) => {
  return request({
    url: "/resource/oss/upload",
    method: "post",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 上传文件
export const identify = (data) => {
  return request({
    url: "/we_chat/show_ticket/identify",
    method: "post",
    data,
  });
};

// 查询基础设置
export const common_config_get = (params) => {
  return request({
    url: "/admin/common/config/get",
    method: "get",
    params,
  });
};
